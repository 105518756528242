import React from 'react';
import { MessageContainer, Message } from 'tock-react-kit';

const PassengerTicketWidget = ({imageUrl, alternative}) => {
    return (
        <MessageContainer>
            <Message>
                <img src={imageUrl} width="250" height="250" alt={alternative}></img>
                <br/>
                <br/>
                <br/>
                <br/>
            </Message>
        </MessageContainer>
    );
};

export default PassengerTicketWidget;