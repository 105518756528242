import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, ThemeProvider, TockContext} from 'tock-react-kit';
import App from './components/App/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import sncfStyles from './_sncf.scss';

const tacId = new URL(document.location.href).searchParams.get('tacId');
const tacTokenId = new URL(document.location.href).searchParams.get('tacTokenId');
const source = new URL(document.location.href).searchParams.get('source');
const media = new URL(document.location.href).searchParams.get('media');
const language = new URL(document.location.href).searchParams.get('lang');

const webContext = {
    tacId: tacId ? tacId : '',
    tacTokenId: tacTokenId ? tacTokenId : '',
    source: source ? source.toUpperCase() : 'DIRECT',
    media: media ? media.toUpperCase() : 'WEB',
};

const theme = createTheme({
    palette: {
        text: {
            user: 'black',
            bot: 'white',
            card: 'black',
            input: 'black'
        },
        background: {
            user: 'white',
            bot: 'transparent',
            card: 'white',
            input: 'white',
        }
    },
    sizing: {
        loaderSize: '8px',
        borderRadius: sncfStyles.borderRadius,
        conversation: {
            width: '720px'
        }
    },
    typography: {
        fontFamily: sncfStyles.fontFamilyBase,
        fontSize: sncfStyles.fontSizeBase
    },
    overrides: {
        chat: `
            background: ${sncfStyles.colorBlue};
            position: relative;`,
        quickReply: `
          background: transparent; 
          color: white; 
          border: solid 1px white;
          margin-bottom: 1em;
          
          &:focus,
          &.focus {
            box-shadow: none;
          }
          
          &:hover,
          &:active {
            background: transparent;
            border-color: white;
          }`,
        card: {
            cardContainer: `
              background: white;
              border: none;
              margin: 2em 0; 
              padding: 2px; 
              width: 18em; 
              transition: transform .2s;
              &:hover {
                transform: scale(1.05);
              }`,
            cardTitle: `
            font-size: ${sncfStyles.fontSizeBase}; 
            font-weight: bold; 
            background: ${sncfStyles.cardBackground};
            margin: 0;
            min-height: 65px;
            padding: 12px 0.5em;
            border-radius: 5px 5px 0 0;
            border-bottom: none;
            `,
            cardSubTitle: `
            font-size: ${sncfStyles.fontSizeSm}; 
            background: ${sncfStyles.cardBackground}; 
            margin: 0;
            min-height : 40px;
            padding: 0 0.4em;`,
            cardButton: `background: white; 
              color: black;
              border: none;
              border-top: 1px solid grey;
              min-width: 17em;
              width: 288px;
              background-color: white;
              position: relative;
              left: -10px;
              top:20px;
              margin:0px;
              border-radius : 0px 0px 8px 8px;
         
              &:focus,
              &.focus {
                box-shadow: none;
              }
              
              &:hover,
              &:active {
                background: white;
                color: ${sncfStyles.colorBlue};
                background-color: white;
                font-weight: bold;
              }`,
        },
        carouselItem: `margin: 0 ${sncfStyles.cardMargin};`,
        carouselArrow: `
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      background: rgba(0,0,0,.2);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      transform-origin: center;
      & svg {
        stroke: white;
        stroke-opacity: .5;
      }
      &:hover,
      &:focus {
        svg {
          stroke: white;
          stroke-opacity: 1;
        }
        background: rgba(0,0,0,.4);
        transform: translateY(-51%) scale(0.95) ;
        -ms-transform: translateY(-51%) scale(0.95) ;
      }
    `,
        chatInput: {
            icon: `
        & > svg {
            fill: grey;
            transform: rotate(45deg) scale(0.6) ;
            top : 0px;
            &:hover {
                fill: ${sncfStyles.colorBlue};
                transform: rotate(45deg) scale(1.1);
            }
        }
        `,
            input: `
        padding: .65625em 1.25em;
        min-height: 2.8125em;
        `,
        },
    }
});

const accessibility = {
  carousel: {
    roleDescription: "Carrousel",
    slideRoleDescription: "Slide",
    previousButtonLabel: "Slide précédente",
    nextButtonLabel: "Slide suivante",
  },
  input: {
    sendButtonLabel: "Envoyer un message",
  },
  qrCarousel: {
    previousButtonLabel: "Bouton précédent",
    nextButtonLabel: "Bouton suivant",
  }
};

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <TockContext>
            <App
              endPoint={process.env.REACT_APP_BOT_ENDPOINT}
              referralParameter={JSON.stringify(webContext)}
              accessibility={accessibility}
              language={language}
              showLanguage={ tacId !== null || tacTokenId !== null }
            />
        </TockContext>
    </ThemeProvider>,
    document.getElementById('chat')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
