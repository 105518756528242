import React, { useState } from 'react';

const languages = [
  { value: 'fr', label: '🇫🇷' },
  { value: 'en', label: '🇬🇧' },
  { value: 'es', label: '🇪🇸' },
  { value: 'de', label: '🇩🇪' },
  { value: 'nl', label: '🇳🇱' },
  { value: 'it', label: '🇮🇹' }
];

const FlagSelect = ({defaultLanguage}) => {
    const [selectedOption, setSelectedOption] = useState(defaultLanguage);

    const handleClick = ((value) => {
      setSelectedOption(value);
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('lang', value);
      window.location.href = currentUrl.toString();
    });

    return (
        <select style={{fontSize: '20px'}} value={selectedOption} onChange={e => handleClick(e.target.value)}>
            {
              languages.map(language =>
                <option key={language.value} value={language.value}>{language.label}</option>
              )
            }
        </select>
    );
};

export default FlagSelect;