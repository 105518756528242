import React, { useEffect, useState } from 'react';
import { Chat } from 'tock-react-kit';
import SncfHeader from '../SncfHeader';
import PassengerTicketWidget from '../PassengerTicketWidget';

export default function App({ endPoint, referralParameter, accessibility, showLanguage, language }) {
  let defaultLanguage = language ? language : navigator.language;
  const [showBot, setShowBot] = useState(false);

  useEffect(() => {
    if (showLanguage === true) {
      const data = {
        userId: JSON.parse(localStorage.getItem("userId")),
        language: defaultLanguage
      }
      fetch(
        process.env.REACT_APP_BOT_LANGUAGE_ENDPOINT,
        {
          method: "POST",
          body: JSON.stringify(data)
        }
      ).then(() => {
       setShowBot(true);
      });
    } else {
      setShowBot(true);
    }
  }, [showLanguage, showBot, defaultLanguage]);

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <SncfHeader showLanguage={showLanguage} language={defaultLanguage} />
      { showBot && <div style={{ marginTop: '4rem', height: 'calc(100% - 4rem)' }}>
        <Chat
          endPoint={endPoint}
          referralParameter={referralParameter}
          widgets={{ PassengerTicketWidget }}
          disableSse={true}
          accessibility={accessibility}
        />
      </div> }
    </div>
  );
}
